import axios from "axios";
import { xml2json } from "xml-js";
import { isNil } from "~utils";

const url =
  // "https://ec.europa.eu/taxation_customs/vies/services/checkVatService";
  "/tva";

/**
 * @param {String} vat
 * @return {Object}
 */
const getVatXml = async (code, number) => {
  // const code = "BE";
  // const number = "0441557856";
  // const xml = `<?xml version="1.0" encoding="UTF-8"?>
  //   <SOAP-ENV:Envelope xmlns:ns0="urn:ec.europa.eu:taxud:vies:services:checkVat:types" xmlns:ns1="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/">
  //   <SOAP-ENV:Header/>
  //     <ns1:Body><ns0:checkVat><ns0:countryCode>${code}</ns0:countryCode>
  //     <ns0:vatNumber>${number}</ns0:vatNumber>
  //     </ns0:checkVat></ns1:Body>
  //   </SOAP-ENV:Envelope>`;

  // const xml = `
  //     <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"
  //       xmlns:tns1="urn:ec.europa.eu:taxud:vies:services:checkVat:types"
  //       xmlns:impl="urn:ec.europa.eu:taxud:vies:services:checkVat">
  //     <soap:Header>
  //     </soap:Header>
  //     <soap:Body>
  //       <tns1:checkVat xmlns:tns1="urn:ec.europa.eu:taxud:vies:services:checkVat:types"
  //       xmlns="urn:ec.europa.eu:taxud:vies:services:checkVat:types">
  //       <tns1:countryCode>${code}</tns1:countryCode>
  //       <tns1:vatNumber>${number}</tns1:vatNumber>
  //       </tns1:checkVat>
  //     </soap:Body>
  //   </soap:Envelope>
  // `;

  const { data: response } = await axios.get(
    url + "/" + code + "/" + number + "/",
  );
  return response;
};

/**
 * @param {Json} json
 * @return {Object}
 */
const cleanJson = (json) => {
  try {
    // firs we need to check the prefix from the property name
    // "soap:Envelope" or  "env:Envelope" for example
    const preKeyFieldName = Object.keys(json)[0].split(":")[0];

    const envelope = json[`${preKeyFieldName}:Envelope`];

    // console.log({ envelope })
    if (isNil(envelope)) return;
    const _j = envelope[`${preKeyFieldName}:Body`];

    if (
      !isNil(_j[`${preKeyFieldName}:Fault`]) &&
      _j[`${preKeyFieldName}:Fault`].faultstring._text === "INVALID_INPUT"
    ) {
      return { valid: "false" };
    }

    let objPreKey = Object.keys(_j)[0].split(":")[0];
    objPreKey = isNil(objPreKey) ? "" : objPreKey + ":";

    const validVat = _j[`${objPreKey}checkVatResponse`];
    let res = {};
    Object.keys(validVat).forEach((key) => {
      res = {
        ...res,
        [key.replace(`${objPreKey}`, "")]: validVat[key]["_text"],
      };
    });
    return res;
  } catch (error) {
    console.error(error);
    throw new Error(`Unexpected server response structure: ${error.message}`);
  }
};

/**
 * @param {String} vat
 * @return {Object}
 */
const deconstructVat = (vat) => {
  const code = vat.substring(0, 2);
  const number = vat.substring(2, vat.length);

  return {
    code,
    number,
  };
};

/**
 * @param {String} vat
 * @return {Object}
 */
export default async (vat) => {
  const { code, number } = await deconstructVat(vat);
  const xml = await getVatXml(code, number);
  const result = await xml2json(xml, { compact: true, spaces: 4 });
  const json = JSON.parse(result);
  const response = await cleanJson(json);
  return response;
};
