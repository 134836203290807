var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      ref: _vm.makeItemRef("vat-checker"),
      attrs: {
        label: _vm.FormMSG(3, "VAT"),
        "label-for": "vat-checker--input-vat",
      },
    },
    [
      _c(
        "b-input-group",
        [
          _c("b-form-input", {
            attrs: {
              id: "vat-checker--input-vat",
              state: _vm.rendVatResponseStatus,
              placeholder: _vm.FormMSG(4, "Your VAT ..."),
            },
            model: {
              value: _vm.vatPayload,
              callback: function ($$v) {
                _vm.vatPayload = $$v
              },
              expression: "vatPayload",
            },
          }),
          _c(
            "b-input-group-append",
            { staticClass: "cursor-pointer" },
            [
              _c(
                "b-input-group-text",
                { staticClass: "btn-search" },
                [
                  _c(
                    "b-button",
                    {
                      staticStyle: { height: "21px", "line-height": "10px" },
                      attrs: { variant: "primary", disabled: _vm.isVatLoading },
                      on: { click: _vm.handleCheckVat },
                    },
                    [
                      _c("b-spinner", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isVatLoading,
                            expression: "isVatLoading",
                          },
                        ],
                        attrs: { small: "" },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isVatLoading,
                              expression: "!isVatLoading",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(9, "Check")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.rendVatResponseStatus === false
        ? _c("b-form-invalid-feedback", [
            _vm._v("\n\t\t" + _vm._s(_vm.FormMSG(1, "Vat not valid")) + "\n\t"),
          ])
        : _vm._e(),
      _vm.isTimeOutError
        ? _c("b-form-invalid-feedback", [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.FormMSG(
                    876,
                    "Request timed out. Please retry in a moment. Thanks for your patience!"
                  )
                ) +
                "\n\t"
            ),
          ])
        : _vm._e(),
      _vm.rendVatResponseStatus === true
        ? _c(
            "b-form-valid-feedback",
            { attrs: { state: _vm.rendVatResponseStatus } },
            [_vm._v("\n\t\t" + _vm._s(_vm.FormMSG(2, "Valid vat")) + "\n\t")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }