<template>
	<b-form-group :ref="makeItemRef('vat-checker')" :label="FormMSG(3, 'VAT')" label-for="vat-checker--input-vat">
		<b-input-group>
			<b-form-input id="vat-checker--input-vat" v-model="vatPayload" :state="rendVatResponseStatus" :placeholder="FormMSG(4, 'Your VAT ...')" />
			<b-input-group-append class="cursor-pointer">
				<b-input-group-text class="btn-search">
					<b-button variant="primary" :disabled="isVatLoading" @click="handleCheckVat" style="height: 21px; line-height: 10px">
						<b-spinner v-show="isVatLoading" small />
						<span v-show="!isVatLoading">{{ FormMSG(9, 'Check') }}</span>
					</b-button>
				</b-input-group-text>
			</b-input-group-append>
		</b-input-group>
		<b-form-invalid-feedback v-if="rendVatResponseStatus === false">
			{{ FormMSG(1, 'Vat not valid') }}
		</b-form-invalid-feedback>
		<b-form-invalid-feedback v-if="isTimeOutError">
			{{ FormMSG(876, 'Request timed out. Please retry in a moment. Thanks for your patience!') }}
		</b-form-invalid-feedback>
		<b-form-valid-feedback :state="rendVatResponseStatus" v-if="rendVatResponseStatus === true">
			{{ FormMSG(2, 'Valid vat') }}
		</b-form-valid-feedback>
	</b-form-group>
</template>

<script>
import { isNil } from '@/shared/utils';
import getVatData from '@/shared/vat-helper';

import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import * as icons from 'lucide-vue';

/**
 * @file
 * This component validates VAT numbers.
 * 
 * @requires isNil from '@/shared/utils'
 * @requires getVatData from '@/shared/vat-helper'
 * @requires languageMessages from '@/mixins/languageMessages'
 * @requires globalMixin from '@/mixins/global.mixin'
 * @requires icons from 'lucide-vue'
 * 
 * @exports VatCheckerComponent
 */
export default {
  /**
	 * @typedef {Object} Props
	 * @property {String|Number} value - VAT number to be checked.
	 * 
	 * @typedef {Object} Data
	 * @property {Boolean} rendVatResponseStatus - Status of VAT check response.
	 * @property {Boolean} isVatLoading - Loading state of VAT check.
	 * @property {Boolean} isTimeOutError - Timeout error state of VAT check.
	 * @property {Object} defaultVat - Default VAT details.
	 * 
	 * @typedef {Object} Methods
	 * @property {Function} handleCheckVat - Handles the VAT check.
	 * @property {Function} vatResultStatus - Determines the result status of the VAT check.
	 * @property {Function} vatEmitter - Emits an event with the VAT data.
	 * @property {Function} getIconLucide - Returns an icon.
	 */
	name: 'VatCheckerComponent',
	mixins: [globalMixin, languageMessages],
	props: {
		value: {
			type: [String, Number],
			required: false,
			default: null
		}
	},
	data() {
		return {
			rendVatResponseStatus: null,
			isVatLoading: false,
      isTimeOutError: false,
			defaultVat: {
				countryCode: 'BE',
				vatNumber: '',
				name: '',
				address: '',
				originVat: ''
			},
      isDataReceived: false,
		};
	},
	computed: {
		vatPayload: {
			/**
			 * @return {String}
			 */
			get() {
				return isNil(this.value) ? null : this.value;
			},
			/**
			 * @param {String|Number} vatNumber
			 * @return {Emitter}
			 */
			set(vatNumber) {
				this.$emit('input', vatNumber);
			}
		}
	},
	methods: {
    async handleCheckVat() {
      if (this.vatPayload === '' || isNil(this.vatPayload)) return;

      this.isVatLoading = true;
      const _v = this.vatPayload;
      const vat = _v.replace(/[\s.]/g, '').toUpperCase();

      if (!/^[A-Z0-9]*$/.test(vat)) {
        this.createToastForMobile(
          this.FormMSG(43434, 'Input should be alphanumeric'),
          '',
          '',
          'error'
        );
        this.isVatLoading = false;
        return;
      }

      if (isNil(vat)) {
        this.isVatLoading = false;
        this.rendVatResponseStatus = false;
      }

      /**
       * time out testing function
       */
      // const getVatDataMock = async (vat) => {
      //   return new Promise(resolve => {
      //     // Set a delay that is longer than the timeout duration
      //     setTimeout(() => {
      //       resolve({ message: 'testrun function' });
      //     }, 15000); // 15 seconds
      //   });
      // }

      // Setting a timeout
      const timeout = new Promise((resolve, reject) => {
        const _t = setTimeout(() => {
          clearTimeout(_t);
          if (this.isDataReceived) return;
          this.isTimeOutError = true;
          reject(this.FormMSG(213123, 'Request timed out'));
        }, 10000) // 10 seconds
      });

      try {
        const vatRes = await Promise.race([
          getVatData(vat),
          // getVatDataMock(vat),
          timeout
        ]);
        
        this.isDataReceived = true;

        // Check the response before emitting it
        if (vatRes && vatRes.valid !== 'false') {
          this.vatEmitter(vatRes)
        } else {
          throw new Error('Invalid VAT');
        }
        // this.vatEmitter(vatRes)
      } catch (error) {
        console.error({ error })
        this.createToastForMobile(error, '', '', 'error');
        return;
      } finally {
        this.isVatLoading = false;
      }
    },

		/**
		 * @param {Object} obj.valid
		 */
		vatResultStatus({ valid }) {
			if (isNil(valid)) return;
			const isFailed = valid === 'false';
			this.rendVatResponseStatus = !isFailed;
		},

		/**
		 * @param {Object} vat
		 * @return {Emitter}
		 */
		vatEmitter(vat) {
			this.$emit('change', { ...vat, originVat: this.vatPayload });
		},
		getIconLucide(name) {
			return icons[name];
		}
	}
};
</script>
